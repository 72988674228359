<template>
    <div>
        <avue-crud
            ref="crud" :option="tableOption" :data="list" :page="page"
            v-model="form" :table-loading="listLoading" 
            @search-change="searchChange"
            @refresh-change="refreshChange"
            @size-change="sizeChange"
            @current-change="currentChange"
            @search-reset='refreshChange'>
            <template #chain_protocol='scope'>
                <div>{{scope.row.chain}} / {{scope.row.protocol}}</div>
            </template>
            <template #selection='scope'>
                <el-radio-group size="large" @change="changeFun(scope.row)">
                    <el-radio-button :label="$t('button.checks')" />   
                </el-radio-group>
            </template>
        </avue-crud>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,defineEmits } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/coin";
    
    const crud = ref(null)
    const list = ref([])
    const form = ref({})
    const listLoading = ref(false)
    const tableOption = ref({});
    tableOption.value = tableOption1(false)
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const emit = defineEmits(['getCoin'])
    const changeFun = (e)=>{
        emit('getCoin',{
            chain: e.chain,
            protocol: e.protocol,
            coin: e.coin,
            coinId: e.coinId,
        })
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.getCurrencyList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }
        })
    }
    const searchChange = (e, done)=>{
        page.currentPage = 1;
        if(e.createTime){
            e.beginTime = e.createTime[0]
            e.endTime = e.createTime[1]
            delete e.createTime
        }
        if(e.chain_protocol){
            e.chain = e.chain_protocol[0]
            e.protocol = e.chain_protocol[1]
        }
        getList(e)
        done()
    }
    const refreshChange = ()=>{
        page.currentPage = 1;
        getList()
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    getList(1)
</script>

<style lang="scss" scoped>
</style>