import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

const dicFormatter = (e)=>{
    let obj_1 = []
    for(let item of e){
        if(item){
            let obj_ = {
                label: item.chain,
                value: item.chain,
            }
            if(item.chainProtocolInfos){
                let obj_c = []
                for(let items of item.chainProtocolInfos){
                    obj_c.push({
                        label: items.protocol,
                        value: items.protocol
                    })
                }
                obj_.children = obj_c
                obj_1.push(obj_)
            }
        }
    }
    return obj_1
}
export const tableOption1 = (bool_) => {
    return {
        border: true,
        stripe: true,
        highlightCurrentRow	: true,
        align: 'center',
        editBtn: false,
        delBtn: false,
        addBtn: false,
        viewBtn: false,
        refreshBtn: false,
        columnBtn: false,
        height: 'auto',
        menu: false,
    // selection:true,
    column: [{
        label: t('currencySet.name31'),
        prop: 'selection',
    },{
        label: t('currencySet.text1'),
        prop: 'coin',
        rules: [
            { required: true, message: t('inputTit.shangchuan'), trigger: 'blur'}
        ],
        // search: true,
    },{
        label: 'Icon',
        prop: 'coinIcon',
        type: 'upload',
        listType: 'picture-img',
        rules: [
            { required: true, message: t('inputTit.shangchuan'), trigger: 'blur'}
        ],
        action:'/admin/sys-file/uploadFileToOSS',
        span:24,
    },{
        label: t('currencySet.text2'),
        prop: 'coinType',
        value: 1,
        rules: [
            { required: true, message: t('inputTit.xuanze'), trigger: 'blur' }
        ],
        span:24,
        type:'select',
        props: {
            label: "label",
            value: "value"
        },
        dicData: [{ label: t('currencySet.text3'), value: 1 },{label: t('currencySet.text4'),value:2}],
    }, {
        label: t('currencySet.text5'),
        prop: 'chain_protocol',
        type: bool_?'input':'cascader',
        rules: [
            { required: true, message: t('inputTit.xuanze'), trigger: 'blur' }
        ],
        // search: true,
        dicUrl: '/chain/chainprotocolinfo/getChainProtocolSymbol',
        span:24,
        dicQuery: {status:1,protocolType:1},
        dicFormatter: dicFormatter,
        disabled: bool_,
    }]
}
}
